import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeaderPress from "../components/HeaderPress";
import CardPress from "../components/Cards/CardPress";

import "../utils/general.scss";
import "../utils/page-press.scss";

const PressComunicati = ({ pageContext }) => {
    const data = useStaticQuery(graphql`
    {
        allNews(filter: {metadatas: {type: {eq: "comunicato"}}}, sort: {fields: publishedAt___date, order: DESC}) {
            edges {
                node {
                    id
                    name
                    description
                    publishedAt {
                        date(formatString: "DD/MM/YYYY")
                        monthIT: date(formatString: "MMMM", locale: "it")
                        monthEN: date(formatString: "MMMM", locale: "en")
                        year: date(formatString: "YYYY")
                    }
                    metadatas {
                        name_eng
                    }
                }
            }
        }
    }
    `);

    let prevMonth = "";
    const news = data.allNews.edges.map((item, index) => {
        let showMonthLabel = false;
        item = item.node;

        if (prevMonth !== item.publishedAt.monthIT) {
            showMonthLabel = true;
            prevMonth = item.publishedAt.monthIT;
        }
        return (
            <>
                {showMonthLabel &&
                    <h5 className="subtitle">
                        {(typeof window !== 'undefined' && window.localStorage.language === "en") ? item.publishedAt.monthEN : item.publishedAt.monthIT} {item.publishedAt.year}
                    </h5>
                }
                <CardPress 
                    to={`news/${item.id}`}
                    date={item.publishedAt.date}
                    title={(typeof window !== 'undefined' && window.localStorage.language === "en" && item.metadatas && item.metadatas.name_eng) ? item.metadatas.name_eng : item.name}
                    text={item.description}
                />
            </>
        )
    });

    return (
        <Layout pageContext={pageContext}>
            <SEO title={`${pageContext.localeResources['press.comunicati.title']} | ${pageContext.localeResources['menu.press']}`}>
                {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
            </SEO>

            <HeaderPress title={pageContext.localeResources['press.comunicati.title']} />

            <section className="section-press colorful-bg">
                <div className="container section">
                    { news }

                    {news.length < 1 &&
                        <p style={{width: "100%", textAlign: "center"}}>{pageContext.localeResources['feedback.noComunicati']}</p>
                    }
                </div>
            </section>
        </Layout>
    )
}

export default PressComunicati
